<template>
  <div style="padding: 10px">
    <el-card>
      <div id="myChart" :style="{ width: '800px', height: '600px' }"></div>
    </el-card>
  </div>
</template>

<script>
import { getAllCountries, getRoleEcharts } from '@/api/xrg/role'

export default {
  name: 'XrgEcharts',
  data() {
    return {
      allCountries: []
    }
  },
  async mounted() {
    this.initCountries()
    this.drawLine()
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$root.echarts.init(document.getElementById('myChart'))
      let option = {
        title: {
          text: '各国家用户比例统计图',
          subtext: '虚拟数据',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: 'bottom',
          trigger: 'item'
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            name: '用户比例',
            type: 'pie',
            radius: '55%',
            center: ['50%', '45%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: [],
            countries: []
          }
        ]
      }
      getRoleEcharts().then((res) => {
        res.forEach((item) => {
          option.series[0].data.push({ name: item.address, value: item.count })
        })
        // 绘制图表
        myChart.setOption(option)
        myChart.on('click', this.myChartClickFunc)
      })
    },
    initCountries() {
      getAllCountries().then((resp) => {
        if (resp) {
          this.allCountries = resp
        }
      })
    },
    async myChartClickFunc(param) {
      let name = param.data.name
      // 根据国家名称查询国家编号
      let countryId
      this.allCountries.forEach((country) => {
        if (country.name === name) {
          countryId = country.id
        }
      })
      // 跳转记录页面并给过去查询参数
      this.$router.push({
        path: '/xrg/role',
        query: {
          countryId: countryId
        }
      })
    }
  }
}
</script>

<style></style>
