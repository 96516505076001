<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          placeholder="文件名称"
          v-model="searchFiles.name"
          clearable
          @keydown.enter.native="
            searchFiles.currentPage = 1
            searchFiles.size = 10
            initFiles()
          "
        ></el-input>
        <el-select
          v-model="searchFiles.type"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="文件类型"
          clearable
          filterable
          @change="
            searchFiles.currentPage = 1
            searchFiles.size = 10
            initFiles()
          "
        >
          <el-option v-for="item in types" :key="item.code" :label="item.desc" :value="item.code"></el-option>
        </el-select>
        <el-button
          icon="el-icon-delete"
          type="warning"
          @click="
            searchFiles.name = ''
            searchFiles.type = null
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          icon="el-icon-search"
          @click="
            searchFiles.currentPage = 1
            searchFiles.size = 10
            initFiles()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="showAddFilesView"
          v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Files') !== -1"
        >
          添加文件
        </el-button>
      </div>
    </div>

    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="filesData"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="文件名称" align="center" prop="name"></el-table-column>
        <el-table-column label="文件类型" align="center" prop="typeName"></el-table-column>
        <el-table-column label="文件图片展示" align="center" width="100">
          <template #default="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="initUrl(scope.row.url)"
              :preview-src-list="[initUrl(scope.row.url)]"
              v-if="scope.row.type === 2"
            >
              <template #error>
                <div class="image-slot" style="text-align: center; line-height: 49px; border: 1px solid #ccc">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="文件描述" align="center" width="500">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.remark && toText(scope.row.remark).length > 15"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.remark }}</span>
              <span v-if="scope.row.remark" v-html="$options.filters.ellipsis(scope.row.remark)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.remark" v-html="toText(scope.row.remark)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Files') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateFilesView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="
                roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Files') !== -1
              "
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteFiles(scope.row)"
            >
              删除
            </el-button>
            <el-button
              size="mini"
              style="padding: 3px"
              type="success"
              v-if="scope.row.type === 5"
              @click="linkClick(scope.row.url)"
            >
              跳转
            </el-button>
            <el-button
              size="mini"
              style="padding: 3px"
              type="success"
              v-else
              @click="downloadClick(scope.row.name, scope.row.url)"
            >
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :current-page="searchFiles.currentPage"
        :page-size="searchFiles.size"
        :total="total"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      @close="closeDialog()"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <el-form ref="filesForm" :model="files" :rules="rules">
        <table>
          <tr>
            <el-form-item label="文件名称" prop="name">
              <el-input size="mini" style="width: 200px" v-model="files.name" placeholder="请输入文件名称"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="文件类型" prop="type">
              <el-select
                v-model="files.type"
                size="mini"
                style="width: 200px; margin-right: 20px"
                placeholder="请选择文件类型"
                clearable
                filterable
                @change="getFilesUploadUrl"
              >
                <el-option v-for="item in types" :key="item.code" :label="item.desc" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr v-if="files.type">
            <div style="margin-left: 100px">
              <span v-if="files.type === 1" style="color: red; font-size: 10px">支持的格式: doc docx xls xlsx pdf</span>
              <span v-if="files.type === 2" style="color: red; font-size: 10px">支持的格式: png jpeg jpg gif</span>
              <span v-if="files.type === 3" style="color: red; font-size: 10px">支持的格式: mp3 aac m4a flac wav</span>
              <span v-if="files.type === 4" style="color: red; font-size: 10px">支持的格式: mp4 mkv</span>
              <span v-if="files.type === 5" style="color: red; font-size: 10px">支持的格式: http:// https://</span>
            </div>
            <el-form-item label="文件地址" prop="url" v-if="files.type === 5">
              <el-input size="mini" style="width: 200px" v-model="files.url" placeholder="请输入文件地址"></el-input>
            </el-form-item>
            <el-form-item label="文件地址" prop="url" v-else>
              <el-upload ref="upload" :show-file-list="false" :action="filesUploadUrl" :on-success="filesUploadSuccess">
                <el-button type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <!-- 如果类型是图片 展示上传的图片 -->
            <div style="margin-left: 130px" v-if="files.type === 2">
              <el-image
                v-if="files.url !== undefined && files.url !== null && files.url !== ''"
                style="width: 100px; height: 100px; margin-top: 20px"
                :src="initUrl(files.url)"
                :preview-src-list="[initUrl(files.url)]"
              ></el-image>
            </div>
          </tr>
          <tr>
            <el-form-item label="文件描述">
              <el-input
                type="textarea"
                :rows="10"
                size="mini"
                style="width: 200px"
                v-model="files.remark"
                placeholder="请输入文件描述"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doFiles">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { filePageData, getTypes, addFile, updateFile, deleteFile } from '@/api/other/files'
import baseUrl from '@/utils/baseUrl'

export default {
  name: 'Files',
  data() {
    return {
      loading: false,
      searchFiles: {
        name: '',
        type: null,
        currentPage: 1,
        size: 10
      },
      files: {
        id: null,
        url: '',
        name: '',
        type: null,
        remark: ''
      },
      types: [],
      total: 0,
      filesData: [],
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入文件名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择文件类型', trigger: 'change' }],
        url: [{ require: true, message: '请上传或填写文件地址', trigger: 'blur' }]
      },
      filesUploadUrl: process.env.VUE_APP_UPLOAD_API_HOST + '/files/upload',
      roleButton: store.getters.currentRole
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return ''
      }
      let input = value
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
        .replace(/<[^>]+?>/g, '')
        .replace(/\s+/g, ' ')
        .replace(/ /g, ' ')
        .replace(/>/g, ' ')
      if (input.length > 15) {
        return input.slice(0, 15) + '...'
      } else {
        return input
      }
    }
  },
  computed: {},
  mounted() {
    this.initFiles()
    this.initTypes()
  },
  methods: {
    initFiles() {
      this.loading = true
      filePageData(this.searchFiles).then((resp) => {
        this.loading = false
        if (resp) {
          this.filesData = resp.data
          this.total = resp.total
        }
      })
    },
    initTypes() {
      getTypes().then((resp) => {
        if (resp) {
          this.types = resp
        }
      })
    },
    currentChange(page) {
      this.searchFiles.currentPage = page
      this.initFiles()
    },
    sizeChange(size) {
      this.searchFiles.size = size
      this.initFiles()
    },
    deleteFiles(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteFile(data.id).then((resp) => {
            if (resp) {
              this.initFiles()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddFilesView() {
      this.files = {
        id: null,
        url: '',
        name: '',
        type: null,
        remark: ''
      }
      this.dialogVisible = true
      this.title = '添加文件'
    },
    showUpdateFilesView(data) {
      // 拷贝
      this.files = JSON.parse(JSON.stringify(data))
      this.dialogVisible = true
      this.title = '编辑文件'
    },
    doFiles() {
      if (this.files.id) {
        // 编辑
        this.$refs['filesForm'].validate((valid) => {
          if (valid) {
            updateFile(this.files).then((resp) => {
              if (resp) {
                this.dialogVisible = false
                this.initFiles()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['filesForm'].validate((valid) => {
          if (valid) {
            addFile(this.files).then((resp) => {
              if (resp) {
                this.dialogVisible = false
                this.initFiles()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    filesUploadSuccess(res) {
      this.$message.success('上传成功了哦ヾ|≧_≦|〃')
      this.files.url = res.data
    },
    closeDialog() {
      if (this.$refs['upload']) {
        this.$refs['upload'].clearFiles() // 清除历史文件列表
      }
      this.$refs['filesForm'].resetFields()
    },
    linkClick(url) {
      window.open(url, '_blank')
    },
    downloadClick(name, url) {
      if (url.startsWith('http')) {
        window.location = baseUrl.default + '/files?filePath=' + url + '&name=' + name
      } else {
        window.location = baseUrl.default + '/files/cosGet?filePath=' + url + '&name=' + name
      }
    },
    initUrl(image) {
      if (image.startsWith('http')) {
        return baseUrl.default + '/files?filePath=' + image + '&name='
      } else {
        return baseUrl.default + '/files/cosGet?filePath=' + image + '&name='
      }
    },
    getFilesUploadUrl() {
      if (this.files.type === 2) {
        this.filesUploadUrl = process.env.VUE_APP_UPLOAD_API_HOST + '/files/cosUpload';
      } else {
        this.filesUploadUrl = process.env.VUE_APP_UPLOAD_API_HOST + '/files/upload';
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
