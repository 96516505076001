<template>
  <div style="height: 100%">
    <el-container style="height: 100%">
      <el-main>
        <div class="box">
          <el-form
            :rules="rules"
            v-loading="loading"
            element-loading-text="正在登录"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            ref="loginForm"
            :model="loginForm"
            class="loginContainer"
          >
            <h3 class="loginTitle">心灵的世界系统登陆</h3>
            <el-form-item prop="username">
              <el-input
                type="text"
                auto-complete="false"
                v-model="loginForm.username"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                auto-complete="false"
                v-model="loginForm.password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <!--<el-form-item prop="code">
              <el-input
                  type="text"
                  auto-complete="false"
                  v-model="loginForm.code"
                  placeholder="点击图片更换验证码"
                  style="width: 250px; margin-right: 5px"
                  @keydown.enter.native="submitLogin"
              ></el-input>
              <img :src="kaptchaUrl" @click="updateKaptcha"/>
            </el-form-item>-->
            <!-- 图片拼图验证区 -->
            <el-form-item>
              <slide-verify
                :imgs="images"
                ref="slideRef"
                :l="42"
                :r="10"
                :w="350"
                :h="155"
                slider-text="向右滑动"
                @success="onSuccess"
                @fail="onFail"
                @again="onAgain"
              ></slide-verify>
            </el-form-item>
            <el-checkbox v-model="checked" class="loginRemember">记住我</el-checkbox>
            <el-button :disabled="!verified" type="primary" style="width: 100%" @click.prevent="submitLogin">
              登录
            </el-button>
          </el-form>
        </div>
      </el-main>
      <!-- 备案信息 -->
      <el-footer class="footerDown" height="30px">
        <a href="https://beian.miit.gov.cn/" target="_blank">晋ICP备2024030222号</a>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import baseUrl from '@/utils/baseUrl'

export default {
  name: 'Login',
  data() {
    return {
      images: [
        require('../assets/images/login-1.jpg'),
        require('../assets/images/login-2.jpg'),
        require('../assets/images/login-3.jpg')
      ],
      kaptchaUrl: baseUrl.default + '/kaptcha?time=' + new Date(),
      loginForm: {
        username: '',
        password: '',
        code: ''
      },
      loading: false,
      checked: true,
      // 图片验证是否通过
      verified: false,
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
        // code: [{required: true, message: "请输入验证码", trigger: "blur"}],
      }
    }
  },
  mounted() {
    // cookies取用户名密码
    if (this.$cookies.isKey('username')) {
      this.loginForm.username = this.$cookies.get('username')
    }
    if (this.$cookies.isKey('password')) {
      this.loginForm.password = this.$cookies.get('password')
    }
  },
  methods: {
    submitLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.postRequest('/login', this.loginForm).then((resp) => {
            this.loading = false
            if (resp) {
              // checked 查看是否记住我
              if (this.checked) {
                this.$cookies.set('username', this.loginForm.username, { expires: '7D' })
                this.$cookies.set('password', this.loginForm.password, { expires: '7D' })
              }

              // 存储token
              const tokenStr = resp.data.tokenHead + resp.data.token
              window.sessionStorage.setItem('tokenStr', tokenStr)

              let path = this.$route.query.redirect
              this.$router.replace(path == '/' || path == undefined ? '/home' : path)
            }
          })
        } else {
          this.$message.error('请输入所有字段！')
          return false
        }
      })
    },
    updateKaptcha() {
      this.kaptchaUrl = baseUrl.default + '/kaptcha?time=' + new Date()
    },
    onSuccess() {
      // 成功 可以点击登录按钮
      this.verified = true
    },
    onFail() {},
    onAgain() {
      this.$message.error("请再尝试一下吧 (●'◡'●)")
      this.$refs.slideRef.reset()
    }
  }
}
</script>

<style>
.loginContainer {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 100px auto;
  width: 350px;
  padding: 15px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.loginTitle {
  color: #5297f5;
  margin: 0px auto 40px auto;
  text-align: center;
  font-family: '方正姚体', Geneva, Arial, Helvetica, sans-serif;
}

.loginRemember {
  text-align: left;
  margin: 0px 0px 15px 0px;
}

.el-form-item__content {
  display: flex;
  align-items: center;
}

.box {
  height: calc(100vh - 30px);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  /*background: #b0d0fa;*/
  background: url('~@/assets/images/login-4.jpg') center center no-repeat;
  background-size: 100% 100%;
}

.footerDown {
  line-height: 30px;
  background-color: #eaeaea;
  text-align: right;
}
</style>
