import { deleteRequest, getRequest, postRequest, putRequest } from "@/utils/api.js"

// 获取所有的等级类型
export function getLevelTypes() {
	return getRequest('/xrg/level/getLevelTypes');
}

// 获取所有的组件信息
export function getComponents() {
	return getRequest('/xrg/level/getComponents');
}

// 等级分页查询
export function levelPageData(params = {}) {
	return postRequest('/xrg/level/getPageData', params);
}

// 删除等级信息
export function deleteLevel(id) {
	return deleteRequest('/xrg/level/' + id);
}

// 新增等级信息
export function addLevel(params = {}) {
	return postRequest('/xrg/level', params);
}

// 更新等级信息
export function updateLevel(params = {}) {
	return putRequest('/xrg/level', params);
}