import { deleteRequest, getRequest, postRequest, putRequest } from "@/utils/api.js"

// 获取所有等级权限
export function getAllPermiss() {
	return getRequest('/sys/permiss');
}

// 获取所有菜单
export function getAllMenus() {
	return getRequest('/sys/permiss/menus');
}

// 添加等级权限
export function addPermiss(params = {}) {
	return postRequest('/sys/permiss', params);
}

// 删除等级权限
export function deletePermiss(id) {
	return deleteRequest('/sys/permiss/' + id);
}

// 根据角色id查询菜单id
export function getMidByRid(lid) {
	return getRequest('/sys/permiss/mid/' + lid);
}