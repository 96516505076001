import { deleteRequest, getRequest, postRequest, putRequest } from "@/utils/api.js";
import { downloadRequest } from "@/utils/download"

// 获取所有的地区信息
export function getAllAreas() {
	return getRequest('/userinfo/areas');
}

// 获取所有的等级信息
export function getAllLevels() {
	return getRequest('/userinfo/levels');
}

// 获取所有的国家信息
export function getAllCountries() {
	return getRequest('/userinfo/countries');
}

// 根据国家查询地区信息
export function getAreasByCountry(countryId) {
	return getRequest('/userinfo/areasByCountry/' + countryId);
}

// 获取所有的按钮权限
export function getAllButtonTypeLevels() {
	return getRequest('/userinfo/buttonLevels');
}

// 导出角色信息
export function exportRole() {
	return downloadRequest('/xrg/role/export');
}

// 角色分页查询
export function rolePageData(params = {}) {
	return postRequest('/xrg/role/getPageData', params);
}

// 删除角色信息
export function deleteRole(id) {
	return deleteRequest('/xrg/role/' + id);
}

// 新增角色信息
export function addRole(params = {}) {
	return postRequest('/xrg/role', params);
}

// 更新角色信息
export function updateRole(params = {}) {
	return putRequest('/xrg/role', params);
}

// 获取角色图表信息
export function getRoleEcharts() {
	return getRequest('/xrg/role/count');
}