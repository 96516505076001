<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          placeholder="等级名称"
          v-model="searchLevel.name"
          clearable
          @keydown.enter.native="
            searchLevel.currentPage = 1
            searchLevel.size = 10
            initLevels()
          "
        ></el-input>
        <el-select
          v-model="searchLevel.type"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="等级类型"
          clearable
          filterable
          @change="
            searchLevel.currentPage = 1
            searchLevel.size = 10
            initLevels()
          "
        >
          <el-option v-for="item in types" :key="item.code" :label="item.desc" :value="item.code"></el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" @click="searchLevel.name = ''">重置</el-button>
        <el-button
          type="success"
          icon="el-icon-search"
          @click="
            searchLevel.currentPage = 1
            searchLevel.size = 10
            initLevels()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="showAddLevelView"
          v-if="role.buttonLevelComponents !== null && role.buttonLevelComponents.indexOf('Level') !== -1"
        >
          添加等级
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="levels"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column label="等级名称" prop="name"></el-table-column>
        <el-table-column label="组件" prop="component"></el-table-column>
        <el-table-column label="等级排序" sortable prop="sort"></el-table-column>
        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="role.buttonLevelComponents !== null && role.buttonLevelComponents.indexOf('Level') !== -1"
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateLevelView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="role.buttonLevelComponents !== null && role.buttonLevelComponents.indexOf('Level') !== -1"
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteLevel(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchLevel.currentPage"
        :page-size="searchLevel.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogVisible" width="400px">
      <el-form ref="levelForm" :model="level" :rules="rules">
        <table>
          <tr>
            <el-form-item label="等级名称" prop="name">
              <el-input size="mini" style="width: 200px" v-model="level.name" placeholder="请输入等级名称"></el-input>
            </el-form-item>
          </tr>
          <tr v-if="!updateDialogFlag">
            <el-form-item label="等级类型" prop="type">
              <el-select
                v-model="level.type"
                size="mini"
                style="width: 200px"
                placeholder="等级类型"
                clearable
                filterable
              >
                <el-option v-for="item in types" :key="item.code" :label="item.desc" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr v-if="level.type === 2">
            <el-form-item label="组件">
              <el-select
                v-model="level.component"
                size="mini"
                style="width: 200px"
                placeholder="组件"
                clearable
                filterable
              >
                <el-option
                  v-for="item in components"
                  :key="item.component"
                  :label="item.name"
                  :value="item.component"
                ></el-option>
              </el-select>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doLevel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { getLevelTypes, getComponents, levelPageData, addLevel, updateLevel, deleteLevel } from '@/api/xrg/level'

export default {
  name: 'Level',
  data() {
    return {
      levels: [],
      total: 0,
      loading: false,
      types: [],
      components: [],
      searchLevel: {
        name: '',
        type: 1,
        currentPage: 1,
        size: 10
      },
      level: {
        id: null,
        name: '',
        type: null,
        sort: null,
        component: ''
      },
      title: '',
      dialogVisible: false,
      updateDialogFlag: false,
      rules: {
        name: [{ required: true, message: '请输入等级名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择等级类型', trigger: 'change' }]
      },
      role: store.getters.currentRole
    }
  },
  computed: {},
  mounted() {
    this.initLevelTypes()
    this.initLevels()
    this.initComponents()
  },
  methods: {
    initLevelTypes() {
      this.loading = true
      getLevelTypes().then((resp) => {
        this.loading = false
        if (resp) {
          this.types = resp
        }
      })
    },
    initLevels() {
      this.loading = true
      levelPageData(this.searchLevel).then((resp) => {
        this.loading = false
        if (resp) {
          this.levels = resp.data
          this.total = resp.total
        }
      })
    },
    initComponents() {
      this.loading = true
      getComponents().then((resp) => {
        this.loading = false
        if (resp) {
          this.components = resp
        }
      })
    },
    currentChange(page) {
      this.searchLevel.currentPage = page
      this.initLevels()
    },
    sizeChange(size) {
      this.searchLevel.size = size
      this.initLevels()
    },
    deleteLevel(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']等级, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteLevel(data.id).then((resp) => {
            if (resp) {
              this.initLevels()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showUpdateLevelView(data) {
      // 拷贝
      this.level = Object.assign({}, data)
      this.dialogVisible = true
      this.title = '编辑等级'
      // 是否是修改框
      this.updateDialogFlag = true
    },
    showAddLevelView() {
      this.level = {
        id: null,
        name: '',
        type: null,
        sort: null,
        component: ''
      }
      this.dialogVisible = true
      this.title = '添加等级'
      // 是否是修改框
      this.updateDialogFlag = false
    },
    doLevel() {
      if (this.level.id) {
        // 编辑
        this.$refs['levelForm'].validate((valid) => {
          if (valid) {
            updateLevel(this.level).then((resp) => {
              if (resp) {
                this.dialogVisible = false
                this.initLevels()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['levelForm'].validate((valid) => {
          if (valid) {
            addLevel(this.level).then((resp) => {
              if (resp) {
                this.dialogVisible = false
                this.initLevels()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
