<template>
  <div>
    <div>
      <div>
        <el-input
          style="width: 200px; margin-right: 20px"
          placeholder="国家名称"
          v-model="searchContry.name"
          clearable
          @keydown.enter.native="
            searchContry.currentPage = 1
            searchContry.size = 10
            initCountries()
          "
        ></el-input>
        <el-select
          v-model="searchContry.roleId"
          size="mini"
          style="width: 200px; margin-right: 20px"
          placeholder="国家君主"
          clearable
          filterable
          @change="
            searchContry.currentPage = 1
            searchContry.size = 10
            initCountries()
          "
        >
          <el-option v-for="item in roles" :key="item.id" :label="item.realname" :value="item.id"></el-option>
        </el-select>
        <el-button
          icon="el-icon-delete"
          type="warning"
          @click="
            searchContry.name = ''
            searchContry.roleId = null
          "
        >
          重置
        </el-button>
        <el-button
          type="success"
          icon="el-icon-search"
          @click="
            searchContry.currentPage = 1
            searchContry.size = 10
            initCountries()
          "
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="showAddCountryView"
          v-if="role.buttonLevelComponents !== null && role.buttonLevelComponents.indexOf('Country') !== -1"
        >
          添加国家
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
        size="mini"
        :data="countries"
        border
        stripe
        :row-class-name="tableRowClassName"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column prop="name" label="国家名称"></el-table-column>
        <el-table-column prop="roleName" label="国家君主"></el-table-column>
        <el-table-column align="center" label="国家备注">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.remark && toText(scope.row.remark).length > 15"
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{ scope.row.remark }}</span>
              <span v-if="scope.row.remark" v-html="$options.filters.ellipsis(scope.row.remark)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.remark" v-html="toText(scope.row.remark)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="role.buttonLevelComponents !== null && role.buttonLevelComponents.indexOf('Country') !== -1"
              size="mini"
              style="padding: 3px"
              type="primary"
              @click="showUpdateCountryView(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="role.buttonLevelComponents !== null && role.buttonLevelComponents.indexOf('Country') !== -1"
              size="mini"
              style="padding: 3px"
              type="danger"
              @click="deleteCountry(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 5px">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        :current-page="searchContry.currentPage"
        :page-size="searchContry.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></el-pagination>
    </div>

    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogVisible" width="800px">
      <el-form ref="countryForm" :model="country" :rules="rules">
        <table>
          <tr>
            <el-form-item label="国家名称" prop="name">
              <el-input size="mini" style="width: 200px" v-model="country.name" placeholder="请输入国家名称"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="国家君主">
              <el-select
                v-model="country.roleId"
                size="mini"
                style="width: 200px"
                placeholder="国家君主"
                clearable
                filterable
              >
                <el-option v-for="item in roles" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="国家备注">
              <div id="richText"></div>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doCountry">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { getAllRoles, countryPageData, deleteCountry, addCountry, updateCountry } from '@/api/xrg/country'
// wangeditor富文本
import E from 'wangeditor'
let editor

export default {
  name: 'Country',
  data() {
    return {
      countries: [],
      loading: false,
      total: 0,
      searchContry: {
        name: '',
        roleId: null,
        currentPage: 1,
        size: 10
      },
      country: {
        id: null,
        name: '',
        remark: '',
        roleId: null
      },
      dialogVisible: false,
      title: '',
      rules: {
        name: [{ required: true, message: '请输入国家名称', trigger: 'blur' }]
      },
      roles: [],
      role: store.getters.currentRole
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return ''
      }
      let input = value
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
        .replace(/<[^>]+?>/g, '')
        .replace(/\s+/g, ' ')
        .replace(/ /g, ' ')
        .replace(/>/g, ' ')
      if (input.length > 15) {
        return input.slice(0, 15) + '...'
      } else {
        return value
      }
    }
  },
  computed: {},
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  mounted() {
    this.initCountries()
    this.initRoles()
  },
  methods: {
    initCountries() {
      this.loading = true
      countryPageData(this.searchContry).then((resp) => {
        this.loading = false
        if (resp) {
          this.total = resp.total
          this.countries = resp.data
        }
      })
    },
    currentChange(page) {
      this.searchContry.currentPage = page
      this.initCountries()
    },
    sizeChange(size) {
      this.searchContry.size = size
      this.initCountries()
    },
    deleteCountry(data) {
      this.$confirm('此操作将永久删除[' + data.name + ']国家, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteCountry(data.id).then((resp) => {
            if (resp) {
              this.initCountries()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddCountryView(data) {
      this.country = {
        id: null,
        name: '',
        remark: '',
        roleId: null
      }
      this.title = '添加国家'
      this.dialogVisible = true
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 600
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateCountryView(data) {
      // 拷贝
      this.country = Object.assign({}, data)
      this.title = '编辑国家'
      this.dialogVisible = true
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E('#richText')
          editor.config.height = 600
          // 配置上传图片地址
          editor.config.uploadImgServer = process.env.VUE_APP_UPLOAD_API_HOST + '/files/rich/upload'
          // 自定义fileName
          editor.config.uploadFileName = 'file'
          editor.create()
        }
        // 内容赋值
        editor.txt.html(this.country.remark)
      })
    },
    doCountry() {
      if (this.country.id) {
        // 编辑
        this.$refs['countryForm'].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.country.remark = editor.txt.html()
            updateCountry(this.country).then((resp) => {
              if (resp) {
                this.dialogVisible = false
                this.initCountries()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        // 添加
        this.$refs['countryForm'].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.country.remark = editor.txt.html()
            addCountry(this.country).then((resp) => {
              if (resp) {
                this.dialogVisible = false
                this.initCountries()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    initRoles() {
      getAllRoles().then((resp) => {
        if (resp) {
          this.roles = resp
        }
      })
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
