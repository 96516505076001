const user = {
	state: {
		currentRole: JSON.parse(window.sessionStorage.getItem("role")),
	},
	mutations: {
		INIT_ROLE(state, role) {
			state.currentRole = role;
		},
	},
	actions: {}
}

export default user