import { deleteRequest, getRequest, postRequest, putRequest } from "@/utils/api.js"

// 登录
export function login(params = {}) {
	return postRequest('/login', params);
}

// 注销
export function logout() {
	return postRequest('/exit');
}