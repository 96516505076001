<template>
  <div>
    <div class="permissManaTool">
      <el-input
        size="mini"
        placeholder="请输入等级权限名称"
        v-model="level.name"
        @keydown.enter.native="addLevel"
      ></el-input>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="addLevel"
        v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Permiss') !== -1"
      >
        添加等级权限
      </el-button>
    </div>

    <div class="permissManaMain">
      <el-collapse v-model="activeName" accordion @change="change">
        <el-collapse-item :title="l.name" :name="l.id" v-for="(l, index) in levels" :key="index">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>可访问资源</span>
              <el-button
                style="float: right; padding: 3px 0; color: #ff0000"
                type="text"
                icon="el-icon-delete"
                @click="doDeleteLevel(l)"
                v-if="
                  roleButton.buttonLevelComponents !== null &&
                  roleButton.buttonLevelComponents.indexOf('Permiss') !== -1
                "
              ></el-button>
            </div>
            <div>
              <el-tree
                show-checkbox
                :data="allMenus"
                :props="defaultProps"
                :default-checked-keys="selectedMenus"
                node-key="id"
                :key="index"
                ref="tree"
              ></el-tree>
              <div style="display: flex; justify-content: flex-end">
                <el-button size="mini" @click="cancelUpdate">取消修改</el-button>
                <el-button
                  size="mini"
                  type="primary"
                  @click="doUpdate(l, index)"
                  v-if="
                    roleButton.buttonLevelComponents !== null &&
                    roleButton.buttonLevelComponents.indexOf('Permiss') !== -1
                  "
                >
                  确认修改
                </el-button>
              </div>
            </div>
          </el-card>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { getAllMenus, getMidByRid, addPermiss, deletePermiss, getAllPermiss } from '@/api/sys/permiss'

export default {
  name: 'Permiss',
  data() {
    return {
      level: {
        name: ''
      },
      levels: [],
      allMenus: [],
      activeName: -1,
      selectedMenus: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleButton: store.getters.currentRole
    }
  },
  computed: {},
  mounted() {
    this.initLevels()
  },
  methods: {
    initLevels() {
      getAllPermiss().then((resp) => {
        if (resp) {
          this.levels = resp
        }
      })
    },
    initAllMenus() {
      getAllMenus().then((resp) => {
        if (resp) {
          this.allMenus = resp
        }
      })
    },
    addLevel() {
      if (this.level.name) {
        addPermiss(this.level).then((resp) => {
          if (resp) {
            this.initLevels()
          }
        })
      } else {
        this.$message.error('输入等级权限名称才能添加哦~')
      }
    },
    change(lid) {
      if (lid) {
        this.initAllMenus()
        this.selectedMenus = []
        this.initSelectedMenus(lid)
      }
    },
    initSelectedMenus(lid) {
      getMidByRid(lid).then((resp) => {
        if (resp) {
          this.selectedMenus = resp
        }
      })
    },
    cancelUpdate() {
      this.activeName = -1
    },
    doDeleteLevel(level) {
      this.$confirm('此操作将永久删除[' + level.name + ']等级权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deletePermiss(level.id).then((resp) => {
            if (resp) {
              this.initLevels()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    doUpdate(l, index) {
      let tree = this.$refs.tree[index]
      // 获取所有选择的key true 只选择子节点下选中的
      let selectedKeys = tree.getCheckedKeys(true)
      let url = '/sys/permiss/?lid=' + l.id
      selectedKeys.forEach((key) => {
        url += '&mids=' + key
      })
      this.putRequest(url).then((resp) => {
        if (resp) {
          this.initLevels()
          this.activeName = -1
          // 获取当前角色等级 如果变更的是同一个则刷新菜单
          let flag = false
          let role = JSON.parse(window.sessionStorage.getItem('role'))
          role.levels.forEach((item) => {
            if (item.name === l.name) {
              flag = true
            }
          })
          if (flag) {
            location.reload()
          }
        }
      })
    }
  }
}
</script>

<style>
.permissManaTool {
  display: flex;
  justify-content: flex-start;
}

.permissManaTool .el-input {
  width: 300px;
  margin-right: 6px;
}

.permissManaMain {
  margin-top: 10px;
  width: 700px;
}
</style>
