import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login";
import Login2 from "../views/Login2";
import Role from "../views/xrg/Role";
import Level from "../views/xrg/Level";
import Country from "../views/xrg/Country";
import Area from "../views/xrg/Area";
import XrgEcharts from "../views/xrg/XrgEcharts";
import Map from "../views/xrg/Map";
import Permiss from "../views/sys/Permiss";
import Menu from "../views/sys/Menu";
import UserInfo from "@/views/UserInfo";
import Files from "@/views/other/Files";

Vue.use(VueRouter);

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject);
	return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalReplace.call(this, location, onResolve, onReject);
	return originalReplace.call(this, location).catch((err) => err);
};

const routes = [{
		path: "/",
		name: "Login2",
		component: Login2,
		hidden: true
	},
	{
		path: "/home",
		name: "核心管理",
		component: Home,
		children: [{
				path: "/xrg/xrgEcharts",
				name: "xrg图表",
				component: XrgEcharts
			},
			{
				path: "/xrg/map",
				name: "xrg地图",
				component: Map
			},
			{
				path: "/xrg/role",
				name: "角色列表",
				component: Role
			},
			{
				path: "/xrg/level",
				name: "等级列表",
				component: Level
			},
			{
				path: "/xrg/country",
				name: "国家列表",
				component: Country
			},
			{
				path: "/xrg/area",
				name: "地区列表",
				component: Area
			},
		]
	},
	{
		path: "/home",
		name: "系统管理",
		component: Home,
		children: [{
				path: "/sys/permiss",
				name: "权限组管理",
				component: Permiss
			},
			{
				path: "/sys/menu",
				name: "菜单管理",
				component: Menu
			},
		]
	},
	{
		path: "/home",
		name: "通用管理",
		component: Home,
		children: [{
			path: "/other/files",
			name: "文件列表",
			component: Files
		}, ]
	},
	{
		path: "/home",
		name: "个人中心",
		component: Home,
		children: [{
			path: "/userInfo",
			name: "个人中心",
			component: UserInfo
		}, ]
	},
];

const router = new VueRouter({
	routes,
});

export default router;