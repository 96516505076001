/**
 * html转文本
 *
 * @param {*} HTML
 * @returns 文本
 */
export function toText(HTML) {
  let input = HTML
  return input
    .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
    .replace(/<[^>]+?>/g, '')
    .replace(/\s+/g, ' ')
    .replace(/ /g, ' ')
    .replace(/>/g, ' ')
}

/**
 * 列表样式
 *
 * @param {*} param0 列表
 * @returns 样式
 */
export function tableRowClassName({ row, rowIndex }) {
  if (rowIndex % 2 !== 0) {
    return 'warning-row'
  } else if (rowIndex % 2 === 0) {
    return 'success-row'
  }
  return ''
}

export default {
  methods: { toText, tableRowClassName }
}
