<template>
  <div>
    <el-card class="box-card" style="width: 400px">
      <div slot="header" class="clearfix">
        <span>{{ role.username }}</span>
      </div>
      <div>
        <div style="display: flex; justify-content: center">
          <el-upload
            :headers="headers"
            :action="userFaceFilesUploadUrl"
            :data="role"
            :on-success="onSuccess"
            :show-file-list="false"
          >
            <el-image
              title="点击修改用户头像"
              style="width: 100px; height: 100px; border-radius: 50px"
              :src="initUserFace(role.userFace)"
            ></el-image>
          </el-upload>
        </div>
        <div>
          角色昵称：
          <el-tag>{{ role.realname }}</el-tag>
        </div>
        <div>
          角色等级：
          <el-tag>{{ role.level.name }}</el-tag>
        </div>
        <div>
          角色国家：
          <el-tag>{{ role.country.name }}</el-tag>
        </div>
        <div>
          角色地区：
          <el-tag>{{ role.area.name }}</el-tag>
        </div>
        <div>
          角色来源：
          <el-tag v-if="role.source">{{ role.source }}</el-tag>
          <el-tag v-else>-</el-tag>
        </div>
        <div style="display: flex; justify-content: space-around; margin-top: 10px">
          <el-button type="primary" @click="showUpdateRoleView()">修改信息</el-button>
          <el-button
            type="danger"
            @click="
              dialogVisibleForPass = true
              rolePass.password = ''
            "
          >
            修改密码
          </el-button>
        </div>
      </div>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="修改信息"
      :visible.sync="dialogVisible"
      @close="closeDialog()"
      width="30%"
    >
      <el-form ref="roleForm" :model="role" :rules="rules">
        <table>
          <tr>
            <el-form-item label="登录名" prop="username">
              <el-input size="mini" style="width: 200px" v-model="role.username" placeholder="请输入登录名"></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色名称" prop="realname">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="role.realname"
                placeholder="请输入角色名称"
              ></el-input>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色等级" prop="levelId">
              <el-select
                v-model="role.levelId"
                size="mini"
                style="width: 200px"
                placeholder="角色等级"
                clearable
                filterable
              >
                <el-option v-for="item in levels" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="所属国家" prop="countryId">
              <el-select
                v-model="role.countryId"
                @change="getAreasByCountry(role.countryId)"
                size="mini"
                style="width: 200px"
                placeholder="所属国家"
                clearable
                filterable
              >
                <el-option v-for="item in countries" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="所属地区" prop="areaId">
              <el-select
                v-model="role.areaId"
                size="mini"
                style="width: 200px"
                placeholder="所属地区"
                clearable
                filterable
              >
                <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </tr>
          <tr>
            <el-form-item label="角色来源" prop="source">
              <el-input size="mini" style="width: 200px" v-model="role.source" placeholder="请输入角色来源"></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doRole">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="修改密码"
      :visible.sync="dialogVisibleForPass"
      @close="closeDialogForPass()"
      width="30%"
    >
      <el-form ref="rolePassForm" :model="rolePass" :rules="rulesForPass">
        <table>
          <tr>
            <el-form-item label="密码" prop="password">
              <el-input
                size="mini"
                style="width: 200px"
                v-model="rolePass.password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
          </tr>
        </table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleForPass = false">取 消</el-button>
        <el-button type="primary" @click="doRolePass">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { getCurrentAdminInfo } from '@/api/userinfo'
import baseUrl from '@/utils/baseUrl'

export default {
  name: 'UserInfo',
  data() {
    return {
      headers: {
        Authorization: window.sessionStorage.getItem('tokenStr')
      },
      areas: [],
      levels: [],
      countries: [],
      role: {
        id: null,
        level: {
          name: ''
        },
        country: {
          name: ''
        },
        area: {
          name: ''
        },
        username: '',
        password: '',
        realname: '',
        levelId: null,
        countryId: null,
        areaId: null,
        source: '',
        userFace: ''
      },
      rolePass: {
        password: ''
      },
      dialogVisible: false,
      dialogVisibleForPass: false,
      rulesForPass: {
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      rules: {
        username: [{ required: true, message: '请输入登录名', trigger: 'blur' }],
        realname: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        password: [{ required: true, message: '请输入角色密码', trigger: 'blur' }],
        levelId: [{ required: true, message: '请选择角色等级', trigger: 'blur' }],
        countryId: [{ required: true, message: '请选择所属国家', trigger: 'blur' }],
        areaId: [{ required: true, message: '请选择所属地区', trigger: 'blur' }]
      },
      userFaceFilesUploadUrl: process.env.VUE_APP_UPLOAD_API_HOST + '/userinfo/userface'
    }
  },
  mounted() {
    this.initRole()
    this.initCountries()
    this.initLevels()
  },
  methods: {
    initRole() {
      getCurrentAdminInfo().then((resp) => {
        if (resp) {
          this.role = resp
          // 存入用户信息
          store.commit('INIT_ROLE', resp)
          window.sessionStorage.setItem('role', JSON.stringify(resp))
        }
      })
    },
    initCountries() {
      this.getRequest('/userinfo/countries').then((resp) => {
        if (resp) {
          this.countries = resp
        }
      })
    },
    initLevels() {
      this.getRequest('/userinfo/levels').then((resp) => {
        if (resp) {
          this.levels = resp
        }
      })
    },
    closeDialogForPass() {
      this.$refs['rolePassForm'].resetFields()
    },
    closeDialog() {
      this.$refs['roleForm'].resetFields()
    },
    doRolePass() {
      this.$refs['rolePassForm'].validate((valid) => {
        if (valid) {
          this.putRequest('/userinfo/updateCurrentAdminPassword', this.rolePass).then((resp) => {
            if (resp) {
              this.dialogVisibleForPass = false
              this.initRole()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    showUpdateRoleView() {
      let data = this.role
      this.getAreasByCountry(data.countryId)
      this.dialogVisible = true
    },
    getAreasByCountry(countryId) {
      this.getRequest('/userinfo/areasByCountry/' + countryId).then((resp) => {
        if (resp) {
          this.areas = resp
        }
      })
    },
    initUserFace(image) {
      if (image.startsWith('http')) {
        return baseUrl.default + '/files?filePath=' + image + '&name='
      } else {
        return baseUrl.default + '/files/cosGet?filePath=' + image + '&name='
      }
    },
    doRole() {
      this.$refs['roleForm'].validate((valid) => {
        if (valid) {
          this.putRequest('/userinfo', this.role).then((resp) => {
            if (resp) {
              this.dialogVisible = false
              this.initRole()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onSuccess() {
      this.initRole()
    }
  }
}
</script>

<style>
label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
